import React, { FC, forwardRef } from 'react';
import { Link as RrdLink, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { LinkProps as EfcLinkProps, styled } from 'enova-frontend-components';

export type RouterLinkProps = LinkProps &
  EfcLinkProps & {
    hideUnderline?: boolean;
    customComponent?: boolean;
  };

const StyledLink = styled(RrdLink)`
  color: inherit;

  &.hide-underline {
    text-decoration: none;
  }
` as typeof RrdLink;

const RouterLink: FC<Omit<RouterLinkProps, 'component'>> = forwardRef<
  HTMLAnchorElement,
  RouterLinkProps
>(({ className, onClick, hideUnderline, ...rest }, ref) => (
  <StyledLink
    {...rest}
    className={classNames(
      {
        'hide-underline': hideUnderline
      },
      className
    )}
    onClick={(e) => {
      !e.ctrlKey && window.scrollTo(0, 0);
      onClick && onClick(e);
    }}
    ref={ref}
  />
));

RouterLink.displayName = 'RouterLink';

export default RouterLink;
