import { Route, Routes } from 'react-router-dom';
import React, { lazy } from 'react';

import { useUser } from '../../hooks/useUser';
import { AppRoute, hasPermission } from '../../utils/navigation';

const SoknadScreen = lazy(() => import('../soknad'));
const BuildingScreen = lazy(() => import('../building'));
const ExpertToolsScreen = lazy(() => import('../expertTools'));
const RegistrationScreen = lazy(() => import('../registration'));

export const BuildingRoutes = () => {
  const { user } = useUser();

  return (
    <Routes>
      <Route path=":buildingId">
        <Route index path=":tab?" element={<BuildingScreen />} />

        <Route path="registrer-energimerke">
          <Route path=":registrationId">
            <Route path=":step?">
              <Route path=":tab?" element={<RegistrationScreen />} />
            </Route>
          </Route>
        </Route>

        <Route
          path="ny-registrering/:originalRegistrationId?"
          element={<RegistrationScreen />}
        />

        <Route path="soknad">
          <Route path=":soknadId">
            <Route path=":soknadSteg">
              <Route path=":step?">
                <Route path=":tab?" element={<SoknadScreen />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="ny-soknad" element={<SoknadScreen />} />

        {hasPermission(AppRoute.EXPERT_TOOLS_VARME, user) && (
          <Route path="ekspert-varme/:area" element={<ExpertToolsScreen />} />
        )}

        {hasPermission(AppRoute.EXPERT_TOOLS_KULDE, user) && (
          <Route path="ekspert-kulde/:area" element={<ExpertToolsScreen />} />
        )}
      </Route>
    </Routes>
  );
};
