import { generatePath } from 'react-router-dom';

import { StatisticsScreenViews } from '../screens/statistics/utils';
import {
  DetailingStep,
  SoknadSteg
} from '../components/registering/utils/registerEnums';
import { AreaOfExpertise, User, UserType } from '../types/user';
import { XmlRegistrationStep } from '../screens/main/xmlRegistrationView/xmlRegistration/types';

import { ExpertToolsXMLPortfolioStep } from './expertToolsXML/enums';

type TabMap<T> = Record<UserType, T[]>;

// MAIN SCREEN TABS
export enum MainScreenTab {
  BUILDINGS = 'boliger',
  OTHERS_BUILDINGS = 'andres-boliger',
  SEARCH = 'sok',
  XML_OVERVIEW = 'xml-oversikt'
}

export const DefaultMainScreenTab = MainScreenTab.BUILDINGS;

// BUILDING SCREEN TABS
export enum BuildingScreenTab {
  ABOUT = 'om-boligen',
  DOCUMENTS = 'dokumenter-for-enova-soknad',
  ENERGY_ASSESSMENT = 'energivurdering',
  ENERGY_CERTIFICATE = 'energiattest',
  ENERGY_RATING = 'energimerking',
  ENERGY_SAVING_MEASURES = 'energisparende-tiltak'
}

export const BuildingScreenTabMap: TabMap<BuildingScreenTab> = {
  [UserType.PERSON]: [
    BuildingScreenTab.ENERGY_CERTIFICATE,
    BuildingScreenTab.ABOUT,
    BuildingScreenTab.ENERGY_RATING,
    BuildingScreenTab.ENERGY_SAVING_MEASURES,
    BuildingScreenTab.DOCUMENTS
  ],
  [UserType.ADVISOR]: [
    BuildingScreenTab.ENERGY_CERTIFICATE,
    BuildingScreenTab.ABOUT,
    BuildingScreenTab.ENERGY_RATING,
    BuildingScreenTab.ENERGY_SAVING_MEASURES,
    BuildingScreenTab.DOCUMENTS
  ],
  [UserType.EXPERT]: [
    BuildingScreenTab.ENERGY_CERTIFICATE,
    BuildingScreenTab.ABOUT,
    BuildingScreenTab.ENERGY_RATING,
    BuildingScreenTab.ENERGY_ASSESSMENT,
    BuildingScreenTab.ENERGY_SAVING_MEASURES,
    BuildingScreenTab.DOCUMENTS
  ]
};

// EXPERT TOOLS
export enum ExpertToolsKuldeArea {
  KJOLEANLEGG = 'kjoleanlegg',
  VENTILASJON = 'ventilasjon'
}

export enum ExpertToolsVarmeArea {
  VARMEANLEGG = 'varmeanlegg',
  ELKJEL = 'elkjel'
}

export enum ExpertToolsStep {
  UPLOAD = 'last-opp',
  CONFIRMATION = 'bekreftelse'
}

// REGISTER STEPS
export enum RegistrationCommonStep {
  ABOUT = 'om-boligen',
  PARAMETERS = 'parameter',
  MEASURES = 'tiltak',
  SUMMARY = 'oppsummering',
  ENERGY_CERTIFICATE = 'energiattest'
}

export type RegistrationStep = RegistrationCommonStep | DetailingStep;

export enum RegistrationSummaryTab {
  CALCULATED_ENERGY_RATING = 'beregnet-energimerke',
  RESULT_CALCULATION = 'beregning-av-resultat',
  REVIEW = 'se-over',
  MEASURES = 'tiltak',
  DOCUMENTS = 'dokumenter'
}

// APP ROUTES
export enum AppRoute {
  HOME,
  BUILDING = 'bygg',
  CREATE_BUILDING = 'opprett-bygg',
  DIRECT_LOGIN = 'direct-login',
  EXPERT_TOOLS_KULDE = 'ekspert-kulde',
  EXPERT_TOOLS_VARME = 'ekspert-varme',
  EXPERT_TOOLS_XML = 'ekspertregistrering',
  EXPERT_TOOLS_XML_PORTFOLIO = 'ekspertregistrering-portefolje',
  LOGIN = 'login',
  LOGOUT = 'logout',
  MAIN = 'main',
  NEW_REGISTRATION = 'ny-registrering',
  PROFILE = 'profil',
  REGISTRATION = 'registrer-energimerke',
  SOKNAD = 'soknad',
  STATISTICS = 'statistikk',
  TRACELOG = 'tracelog',
  XML_REGISTRATION = 'xml-registrering'
}

// PATH PARAMS
export type BuildingId = { buildingId: string };

export type RegistrationId = { registrationId: string };

export type BuildingScreenParams = BuildingId & {
  tab?: BuildingScreenTab;
};

export type ExpertToolsParams = BuildingId & {
  area: ExpertToolsVarmeArea | ExpertToolsKuldeArea;
};

export type XmlEnhetParams = RegistrationId & {
  step: XmlRegistrationStep;
};

type NewRegistrationScreenParams = BuildingId & {
  originalRegistrationId?: string;
};

export type RegistrationScreenParams = BuildingId &
  RegistrationId & {
    step?: RegistrationStep;
  };

export type RegistrationSummaryScreenParams = BuildingId &
  RegistrationId & {
    step: RegistrationCommonStep.SUMMARY;
    tab?: RegistrationSummaryTab;
  };

type SoknadScreenBaseParams = {
  soknadId: string;
  soknadSteg: SoknadSteg;
};

export type SoknadScreenParams = Omit<
  RegistrationScreenParams,
  'registrationId'
> &
  SoknadScreenBaseParams;

export type ExpertToolsXMLPortfolioWorklistParams = {
  portefoljeId: string;
  step?: ExpertToolsXMLPortfolioStep;
};

export type AllRegistrationScreenParams = NewRegistrationScreenParams &
  RegistrationScreenParams &
  SoknadScreenParams;

export type MainScreenParams = { tab: MainScreenTab };

export type StatisticsScreenParams = { view?: StatisticsScreenViews };

export type XmlFlerboligParams = {
  flerboligId: string;
  step: XmlRegistrationStep;
};

// PATHS
export const MainScreenPath = `/:tab`;
export const BuildingBasePath = `/${AppRoute.BUILDING}/:buildingId`;
export const BuildingScreenPath = `${BuildingBasePath}/:tab?`;
export const StatisticsPath = `/${AppRoute.STATISTICS}/:view?`;
export const CreateBuildingPath = `/${AppRoute.CREATE_BUILDING}`;
export const NewSoknadPath = `${BuildingBasePath}/soknad/ny-soknad`;
export const ExpertToolsVarmePath = `${BuildingBasePath}/ekspert-varme/:area`;
export const ExpertToolsKuldePath = `${BuildingBasePath}/ekspert-kulde/:area`;
export const SoknadPath = `${BuildingBasePath}/soknad/:soknadId/:soknadSteg/:step?/:tab?`;
export const XmlEnhetPath = `/${AppRoute.XML_REGISTRATION}/enhet/:step/:registrationId?`;
export const XmlFlerboligPath = `/${AppRoute.XML_REGISTRATION}/flerbolig/:step/:flerboligId?`;
export const ExpertToolsXMLPortfolioBasePath = `/${AppRoute.EXPERT_TOOLS_XML_PORTFOLIO}/`;
export const NewRegistrationPath = `${BuildingBasePath}/ny-registrering/:originalRegistrationId?`;
export const RegistrationPath = `${BuildingBasePath}/registrer-energimerke/:registrationId/:step?/:tab?`;
export const ExpertToolsXMLPortfolioWorklistPath = `/${AppRoute.EXPERT_TOOLS_XML_PORTFOLIO}/arbeidsliste/:portefoljeId/:step?`;

// PATH GENERATORS
export const getBuildingScreenPath = (params: BuildingScreenParams): string =>
  generatePath(BuildingScreenPath, params);

export const getXmlEnhetPath = (params: XmlEnhetParams): string =>
  generatePath(XmlEnhetPath, params);

export const getXMLFlerboligPath = (params: XmlFlerboligParams): string =>
  generatePath(XmlFlerboligPath, params);

export const getExpertToolsXMLPortfolioBasePath = (): string =>
  generatePath(ExpertToolsXMLPortfolioBasePath);

export const getExpertToolsXMLPortfolioWorklistPath = (
  params: ExpertToolsXMLPortfolioWorklistParams
): string => generatePath(ExpertToolsXMLPortfolioWorklistPath, params);

export const getMainScreenPath = (params: MainScreenParams): string =>
  generatePath(MainScreenPath, params);

export const getNewRegistrationScreenPath = (
  params: NewRegistrationScreenParams
): string => generatePath(NewRegistrationPath, params);

export const getRegistrationScreenPath = (
  params: RegistrationScreenParams
): string => generatePath(RegistrationPath, params);

export const getSoknadScreenPath = (params: SoknadScreenParams): string =>
  generatePath(SoknadPath, params);

export const getStatisticsScreenPath = (
  params?: StatisticsScreenParams
): string => generatePath(StatisticsPath, params);

export const hasPermission = (appRoute: AppRoute, user?: User): boolean => {
  if (appRoute === AppRoute.STATISTICS) return true;
  if (!user) return false;

  switch (appRoute) {
    case AppRoute.EXPERT_TOOLS_VARME:
      return !!(
        user.type === UserType.EXPERT &&
        user.fagomrader?.includes(AreaOfExpertise.KJELOGVARMEANLEGG)
      );
    case AppRoute.EXPERT_TOOLS_KULDE:
      return !!(
        user.type === UserType.EXPERT &&
        user.fagomrader?.includes(AreaOfExpertise.VENTILASJONOGKJOLEANLEGG)
      );
    case AppRoute.EXPERT_TOOLS_XML_PORTFOLIO:
    case AppRoute.EXPERT_TOOLS_XML:
    case AppRoute.XML_REGISTRATION:
      return (
        user.type !== UserType.PERSON &&
        (user.fagomrader?.includes(AreaOfExpertise.BYGNINGSEKSPERT) ||
          user.fagomrader.includes(AreaOfExpertise.ENERGIRADGIVER))
      );
    case AppRoute.SOKNAD:
      return (
        user.type === UserType.ADVISOR ||
        user.fagomrader.includes(AreaOfExpertise.ENERGIRADGIVER)
      );
    default:
      return true;
  }
};

export const camelCasify = (str: string): string =>
  str
    .replaceAll('-', ' ')
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      +match === 0
        ? ''
        : index === 0
        ? match.toLowerCase()
        : match.toUpperCase()
    );

export const kebabCasify = (str: string): string =>
  str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .split(' ')
    .join('-');

export const withAnchor = (path: string, anchor: string): string =>
  [path, anchor].join('#');
