import React, { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  EnovaContextProvider,
  reservedKeys,
  useLocalStorage
} from 'enova-frontend-components';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';

import { UserProvider } from './hooks/useUser/provider';
import i18n from './i18n';
import store from './store';
import { queryClient } from './utils/react-query';
import { AuthenticationProvider } from './hooks/useAuthentication/provider';
import { AppRoutes } from './screens/routes';

type lngKey = 'nb-NO' | 'nn-NO' | 'en';

const App: FC = () => {
  const [envLng, setEnvLng] = useLocalStorage(
    reservedKeys.lng.env,
    window.config.VITE_LANG || navigator.language
  );

  const [lng, setLng] = useLocalStorage<lngKey>(
    reservedKeys.lng.app,
    (window.config.VITE_LANG || navigator.language) as lngKey
  );

  useEffect(() => {
    if (!envLng || envLng !== window.config.VITE_LANG) {
      setEnvLng(window.config.VITE_LANG || navigator.language);
      setLng((window.config.VITE_LANG || navigator.language) as lngKey);
      i18n.changeLanguage(window.config.VITE_LANG || navigator.language);
    } else i18n.changeLanguage(lng as string);
  }, [envLng, window.config.VITE_LANG]);

  return (
    <Provider store={store}>
      <EnovaContextProvider locale={lng}>
        <AuthenticationProvider>
          <QueryClientProvider client={queryClient}>
            <UserProvider>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </UserProvider>
          </QueryClientProvider>
        </AuthenticationProvider>
      </EnovaContextProvider>
    </Provider>
  );
};

export default App;
